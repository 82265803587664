import React, { useContext, useEffect, useState } from 'react'
import './Course.css'
import axios from 'axios'
import { CircularProgress, Collapse } from '@mui/material'
import { ReactComponent as Down_Icon } from '../../Assets/Down_Icon.svg'
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import { ReactComponent as Ellipse_19 } from '../../Assets/Ellipse_19.svg'
import { ReactComponent as TotalPoints } from '../../Assets/icn - total points earned.svg'
import { ReactComponent as TotalVideos } from '../../Assets/icn - learning streak.svg'
import { ReactComponent as TotalTasks } from '../../Assets/icn - tasks submitted.svg'
import { ReactComponent as TotalTopics } from '../../Assets/icn - topics completed.svg'
import { ReactComponent as Above0Badge } from '../../Assets/icn-interleap-progress-badge-10.svg'
import { ReactComponent as Above50Badge } from '../../Assets/icn-interleap-progress-badge-50.svg'
import { ReactComponent as Above100Badge } from '../../Assets/icn-interleap-progress-badge-100.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from '../../Config'
import { GeneralContext } from '../../Context'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';
import "react-circular-progressbar/dist/styles.css";
import { Calendar } from '../../IconsPath'
import { PointsIcon } from '../../IconsPath'
import { Warning } from '../../IconsPath'
import zipy from "zipyai";

const Course = () => {
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [drawers, setDrawers] = useState([]);
    const [continueModuleId, setContinueModuleId] = useState();
    const [continueTopicId, setContinueTopicId] = useState();
    const [actionButton, setActionButton] = useState();
    const [completedTopicCount, setCompletedTopicCount] = useState(0);
    const [totalTopicCount, setTotalTopicCount] = useState(0);
    const [completedTaskCount, setCompletedTaskCount] = useState(0);
    const [totalTaskCount, setTotalTaskCount] = useState(0);
    const [completedVideoCount, setCompletedVideoCount] = useState(0);
    const [totalVideoCount, setTotalVideoCount] = useState(0);
    const navigate = useNavigate()
    const { course_id } = useParams();
    const { batch_id } = useParams();

    const { tenantDetails, selectedCourse, studentInfo, courseDetails, setCourseDetails, setSelectedTopic, setSelectedModule, setStudentInfo } = useContext(GeneralContext);

    const formatDate = (dateString) => {
        return moment(dateString).format('D MMMM');
    };

    function isTopicStartDateValid(topicStartDateStr) {
        const topicStartDate = new Date(topicStartDateStr);

        const currentDate = new Date();

        return topicStartDate <= currentDate;
    }

    function extractHours(timeString) {
        if (!timeString) {
            return null
        }
        const [hours] = timeString.split(':');
        return parseInt(hours, 10);
    }

    const handleShowChapterSummary = (chapterId) => {
        const newOpenDrawers = [];
        const currentDrawer = drawers[chapterId]
        newOpenDrawers[chapterId] = !currentDrawer;
        setDrawers(newOpenDrawers);
    }

    function formatTopicDate(dateString) {
        if (!dateString) {
            return null
        }
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        let formattedDate = date.toLocaleDateString('en-GB', options)
        formattedDate = formattedDate.replace(/([a-zA-Z]{3})/, (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());

        return formattedDate;
    }

    const getCourseDetails = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            setLoading(true)
            if (student) {
                let url = `${API}/course/student-course-details?course_id=${course_id}&student_id=${student?.student_id}&batch_id=${batch_id}`
                const { data } = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setCourseDetails(data?.data)
                let completedTopicTemp = 0
                let totalTopicTemp = 0
                let completedTaskTemp = 0
                let totalTaskTemp = 0
                let completedVideoTemp = 0
                let totalVideoTemp = 0
                data?.data?.children?.map((module, i) => {
                    module?.children?.map((topic, j) => {
                        totalTopicTemp += 1
                        if (topic?.status === 'COMPLETED') {
                            completedTopicTemp += 1
                        }
                        topic?.tasks?.map((task, k) => {
                            totalTaskTemp += 1
                            if (task?.status === 'COMPLETED') {
                                completedTaskTemp += 1
                            }
                        })
                        topic?.videos?.map((video, k) => {
                            totalVideoTemp += 1
                            if (video?.status === 'COMPLETED') {
                                completedVideoTemp += 1
                            }
                        })
                    })
                })
                setCompletedTopicCount(completedTopicTemp)
                setTotalTopicCount(totalTopicTemp)
                setCompletedTaskCount(completedTaskTemp)
                setTotalTaskCount(totalTaskTemp)
                setCompletedVideoCount(completedVideoTemp)
                setTotalVideoCount(totalVideoTemp)

                setLoading(false)
                return data?.data
            }
            else {
                navigate('/')
            }
            setLoading(false)
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            setLoading(false)
            console.log(e.message)
        }
    }

    const handleStartCourse = async (module, topic) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)
            setSubmitLoading(true)
            const result = await axios.post(`${API}/course/start-course`, {
                course_id: selectedCourse.course_id,
                student_id: studentInfo.student_id,
                batch_id: selectedCourse.batch_id
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Add your token here
                    }
                }
            )
            let url = `${API}/course/student-course-details?course_id=${selectedCourse?.course_id}&student_id=${studentInfo?.student_id}&batch_id=${batch_id}`
            const { data } = await axios.get(url,
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Add your token here
                    }
                })
            setCourseDetails(data?.data)
            data?.data?.children?.forEach((m) => {
                if (m?.node_id === module?.node_id) {
                    m?.children?.forEach((t) => {
                        if (t?.node_id === topic?.node_id) {
                            setSelectedTopic(t)
                        }
                    })
                }
            })
            setSubmitLoading(false)
            navigate('/learn')
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            setSubmitLoading(false)
            console.log(e.message)
        }
    }

    const callZipy = () => {
        let student = localStorage.getItem("student_data")
        student = JSON.parse(student)

        zipy.identify(student?.email, {
            tenant_id: tenantDetails?.tenant_id,
            name: student?.name,
            email: student?.email,
            mobile_number: student?.mobile_number,
        });
    }

    const calculateWeeksDifference = (start, end) => {
        const oneWeek = 1000 * 60 * 60 * 24 * 7;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const diffInMilliseconds = endDate - startDate;
        return Math.round(diffInMilliseconds / oneWeek);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getCourseDetails();
            let flag1 = 0
            let flag2 = 0
            if (!isTopicStartDateValid(data?.start_date) || data?.progress === 100) {
                setDrawers([true]);
            }
            else {
                setDrawers([])
                let temp = []

                data?.children?.some((module, i) => {
                    if (module?.progress === 100) {
                        temp.push(null)
                        return false
                    }
                    else {
                        temp.push(true)
                        setContinueModuleId(i)
                        flag1 = 1
                        module?.children?.some((topic, j) => {
                            if (topic?.progress_percentage === 0) {
                                setContinueTopicId(j)
                                flag2 = 1
                                setActionButton("Start")
                                return true
                            }
                            else if (topic?.progress_percentage !== 100) {
                                setContinueTopicId(j)
                                flag2 = 1
                                setActionButton("Resume")
                                return true
                            }
                            else {
                                return false
                            }

                        })
                        return true
                    }
                })

                // if (!flag2 && flag1) {
                //     setContinueTopicId(0)
                //     setActionButton("Start")
                // }
                // else {
                //     setActionButton("Continue")
                // }
                setDrawers(temp)
            }
        };

        fetchData();
        callZipy();
    }, []);

    return (
        <div className='h-full flex items-center justify-center w-full'>
            {
                loading ?
                    <CircularProgress />
                    :
                    <div className='flex w-full ml-[1%] mr-[1%] gap-4 h-[98%]'>
                        <div className='w-[28%] bg-white rounded-lg p-3'>
                            <div className='text-[24px] font-semibold '>
                                {courseDetails?.name}
                            </div>
                            <div className='text-[16px] text-[#6E6E6F] my-2 font-semibold '>
                                {courseDetails?.description}
                            </div>
                            <div className='flex gap-2 mt-3'>
                                <div className='text-[#6E6E6F] flex gap-1 items-center text-sm font-semibold'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                        <path d={Calendar} fill="#6E6E6F" />
                                    </svg>
                                    {formatDate(courseDetails?.start_date)} - {formatDate(courseDetails?.end_date)}
                                </div>
                                <span className='text-[#6E6E6F] flex items-center ml-2 text-xm gap-1 text-sm font-semibold'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                                        <path d={PointsIcon} fill="#6E6E6F" />
                                    </svg>
                                    {courseDetails?.points} points
                                </span>
                            </div>
                            <div className=' h-[70px] w-[100%] ml-auto mr-auto bg-[#171744] mt-4 rounded-[8px] p-3 flex gap-3'>
                                {
                                    Math.round(courseDetails?.progress) > 99 ?
                                        <Above100Badge />
                                        :
                                        Math.round(courseDetails?.progress) > 50 ?
                                            <Above50Badge />
                                            :
                                            <Above0Badge />
                                }
                                <div className='flex-1'>
                                    <div className='text-[#86BE8C] font-semibold text-[14px] mb-[10px]'>
                                        {Math.round(courseDetails?.progress)}% Complete
                                    </div>
                                    <div className="filler">
                                        {
                                            Number(courseDetails?.progress) !== 0 &&
                                            <div className="progress-bar-fill" style={{ '--dynamic-width': `${courseDetails?.progress}%` }}>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-wrap mt-4'>
                                <div className='flex flex-col gap-1 w-[50%] mb-4'>
                                    <div className='flex items-baseline gap-1'>
                                        <div className='font-semibold text-[25px]'>{courseDetails?.points_earned}</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[14px]'>of</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[15px]'>{courseDetails?.points}</div>
                                    </div>
                                    <div className='flex gap-1 text-[#6E6E6F] text-[14px] font-semibold items-center'>
                                        <TotalPoints />
                                        <div className='opacity-[0.8]'>Total Points Earned</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1 w-[50%]'>
                                    <div className='flex items-baseline gap-1'>
                                        <div className='font-semibold text-[25px]'>{completedVideoCount}</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[14px]'>of</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[15px]'>{totalVideoCount}</div>
                                    </div>
                                    <div className='flex gap-1 text-[#6E6E6F] text-[14px] font-semibold items-center'>
                                        <TotalVideos />
                                        <div className='opacity-[0.8]'>Videos Completed</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1 w-[50%]'>
                                    <div className='flex items-baseline gap-1'>
                                        <div className='font-semibold text-[25px]'>{completedTaskCount}</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[14px]'>of</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[15px]'>{totalTaskCount}</div>
                                    </div>
                                    <div className='flex gap-1 text-[#6E6E6F] text-[14px] font-semibold items-center'>
                                        <TotalTasks />
                                        <div className='opacity-[0.8]'>Tasks Submitted</div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1 w-[50%]'>
                                    <div className='flex items-baseline gap-1'>
                                        <div className='font-semibold text-[25px]'>{completedTopicCount}</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[14px]'>of</div>
                                        <div className='font-semibold text-[#6E6E6F] text-[15px]'>{totalTopicCount}</div>
                                    </div>
                                    <div className='flex gap-1 text-[#6E6E6F] text-[14px] font-semibold items-center'>
                                        <TotalTopics />
                                        <div className='opacity-[0.7]'>Topics Completed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='course-container'
                            style={{
                                // background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${TestBackground}) lightgray 50% / cover no-repeat`
                            }}
                        >
                            <div className="">
                                <div className='flex items-center'>
                                    <div className="course-heading">
                                        Course Schedule
                                    </div>
                                    <div className='flex gap-[12px] ml-auto font-semibold text-[16px] text-[#6E6E6F]'>
                                        <div> {calculateWeeksDifference(courseDetails?.start_date, courseDetails?.end_date)} Weeks </div>
                                        <div> {courseDetails?.children?.length} Modules </div>
                                        <div> {totalTopicCount} Topics </div>
                                    </div>
                                </div>
                                <div className='course-description'>
                                    <div className='flex gap-2 items-center'>
                                        {/* {
                                            isTopicStartDateValid(courseDetails?.start_date) &&
                                            ((courseDetails?.progress) === 100 ?
                                                <Task_Complete_Icon />
                                                :
                                                (courseDetails?.progress) === 0 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                        <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                                    </svg>
                                                    :
                                                    <div className='circular-progress-container w-[16px]'>
                                                        <CircularProgressbar
                                                            value={courseDetails?.progress}
                                                            strokeWidth={50}
                                                            styles={buildStyles({
                                                                strokeLinecap: "butt"
                                                            })}
                                                        />
                                                    </div>)
                                        }
                                        {
                                            isTopicStartDateValid(courseDetails?.start_date) &&
                                            <div className='text-[#42B25B]'>
                                                {Math.round(courseDetails?.progress)}% Complete
                                                <span className=''> • {courseDetails?.points_earned} pts</span>
                                            </div>
                                        }

                                        <div className='text-[#6E6E6F] flex gap-1 items-center text-xm ml-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                                <path d={Calendar} fill="#6E6E6F" />
                                            </svg>
                                            {formatDate(courseDetails?.start_date)} - {formatDate(courseDetails?.end_date)}
                                        </div>
                                        <span className='flex items-center ml-2 text-xm gap-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                                                <path d={PointsIcon} fill="#6E6E6F" />
                                            </svg>
                                            {courseDetails?.points} points
                                        </span> */}
                                        {
                                            !isTopicStartDateValid(courseDetails?.start_date) &&
                                            // courseDetails?.status === "PENDING" &&
                                            <div className='flex items-center gap-[3px] ml-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 15 15" fill="none">
                                                    <path d={Warning} fill="#D48134" />
                                                </svg>
                                                <span className='text-[#D48134]'>You can access this course after {formatDate(courseDetails?.start_date)}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="course-details">
                                {/* <div className="header">
                    <span>Course Plan</span>
                </div> */}
                                <div className="line mt-1 mb-1"></div>
                                <div className='course-content'>
                                    {
                                        courseDetails?.children?.map((module, i) => (
                                            <div key={i} className={`${drawers[i] ? "bg-[#F4F4F4]" : ""} rounded-lg`}>
                                                <div className={`module cursor-pointer`} onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleShowChapterSummary(i)
                                                }}>
                                                    <div>
                                                        <div className={`rounded-lg flex items-center gap-2`}>
                                                            {
                                                                module?.progress === 100 ?
                                                                    <Task_Complete_Icon />
                                                                    :
                                                                    module?.progress === 0 ?
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 8 8" fill="none">
                                                                            <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                                                        </svg>
                                                                        :
                                                                        <div className='circular-progress-container w-[16px]'>
                                                                            <CircularProgressbar
                                                                                value={module?.progress}
                                                                                strokeWidth={50}
                                                                                styles={buildStyles({
                                                                                    strokeLinecap: "butt"
                                                                                })}
                                                                            />
                                                                        </div>
                                                            }


                                                            <span className={`${(drawers[i] || module?.progress === 100) ? "text-black" : "text-[#6E6E6F]"}`}>{module?.node_name}</span>
                                                        </div>
                                                        {/* <div className='flex gap-2 items-center'>
                                                        <div className='text-[#A7A7A7] text-sm'>12-15 Jun</div>
                                                        <div className='text-[#A7A7A7] text-sm'>95 points</div>
                                                        <div className='text-[#A7A7A7] text-sm'>6 hrs, 1 Test</div>
                                                    </div> */}
                                                        <div className={`${drawers[i] ? "" : "hidden"} text-[#6E6E6F] text-xs font-semibold ml-6 mt-2`}>{module?.points} points</div>
                                                    </div>
                                                    <div className='expand-icon-container'>
                                                        <Down_Icon />
                                                    </div>
                                                </div>
                                                {
                                                    drawers[i] &&
                                                    <div className="line"></div>
                                                }
                                                <Collapse in={drawers[i]}>
                                                    <div className='chapter-summary text-left' style={{ fontFamily: 'Figtree, sans-serif' }}>
                                                        {
                                                            module?.children?.map((topic, j) => (
                                                                <div className="topic relative group" key={j}>
                                                                    <div className='flex items-center w-[70%] gap-2'>
                                                                        {
                                                                            Number(topic?.progress_percentage) === 100 ?
                                                                                <Task_Complete_Icon />
                                                                                :
                                                                                Number(topic?.progress_percentage) === 0 ?
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 8 8" fill="none">
                                                                                        <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                                                                    </svg>
                                                                                    :
                                                                                    <div className='circular-progress-container w-[16px]'>
                                                                                        <CircularProgressbar
                                                                                            value={Number(topic?.progress_percentage)}
                                                                                            strokeWidth={50}
                                                                                            styles={buildStyles({
                                                                                                strokeLinecap: "butt"
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                        }

                                                                        {/* <span className={`${(topic?.status === "IN_PROGRESS" || ((Number(topic?.points_earned) / Number(topic?.points)) * 100) === 100) ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{formatTopicDate(topic?.topic_start_date)}</span>
                                                                    <span className={`${(topic?.status === "IN_PROGRESS" || ((Number(topic?.points_earned) / Number(topic?.points)) * 100) === 100) ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{topic?.node_name}</span> */}
                                                                        <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{formatTopicDate(topic?.topic_start_date)}</span>
                                                                        <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold w-[80%]`}>{topic?.node_name}</span>
                                                                    </div>
                                                                    <div className='flex items-center ml-auto'>
                                                                        {
                                                                            ((i === continueModuleId && j === continueTopicId) && topic?.is_accessable && topic?.progress_percentage !== null) ?
                                                                                <div className="button-container ml-auto">
                                                                                    <button
                                                                                        className='text-[12px] text-white rounded-md px-2 w-fit flex items-center justify-center bg-[#3838F1] font-medium'
                                                                                        onClick={() => {
                                                                                            setSelectedTopic(topic)
                                                                                            setSelectedModule(module)
                                                                                            navigate('/learn')
                                                                                        }}
                                                                                    >
                                                                                        {actionButton}
                                                                                    </button>
                                                                                </div>
                                                                                :
                                                                                topic?.progress_percentage !== null &&
                                                                                <div className="button-container ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                                    {
                                                                                        <button
                                                                                            className='text-[12px] text-white rounded-md px-2 w-fit flex items-center justify-center bg-[#3838F1] font-medium'
                                                                                            onClick={() => {
                                                                                                setSelectedTopic(topic)
                                                                                                setSelectedModule(module)
                                                                                                navigate('/learn')
                                                                                            }}
                                                                                        >
                                                                                            {(Number(topic?.progress_percentage) === 100) ?
                                                                                                "See Content"
                                                                                                :
                                                                                                (Number(topic?.progress_percentage) === 0 && topic?.is_accessable) ?
                                                                                                    "Start"
                                                                                                    :
                                                                                                    topic?.is_accessable && "Resume"
                                                                                            }
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                        }
                                                                        <div className='text-xs ml-2 flex gap-2 w-[80px]'>
                                                                            <div className='w-[30px] text-right'>
                                                                                {
                                                                                    (topic?.duration && extractHours(topic?.duration)) ?
                                                                                        <span>{extractHours(topic?.duration) + "hrs"}</span>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </div>
                                                                            <div className='w-[50px] text-right'>
                                                                                {
                                                                                    topic?.points ?
                                                                                        <span>{topic?.points} Pts</span>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>

                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default Course