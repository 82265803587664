import React, { useContext, useEffect, useRef, useState } from 'react'
import Chat from '../Chat/Chat'
import './Learn.css'
import VideoComponent from '../VideoComponent/VideoComponent'
import { CircularProgress, Collapse, Slide } from '@mui/material'
import CodeEditor from '../CodeEditor/CodeEditor'
import axios from 'axios'
import { API } from '../../Config'
import { GeneralContext } from '../../Context'
import { useNavigate } from 'react-router-dom'
import zipy from "zipyai";

const Learn = () => {
    const chatRef = useRef();
    const [showVideoComponent, setShowVideoComponent] = useState(false);
    const [videoComponent, setVideoComponent] = useState(false);
    const [showCodeEditor, setShowCodeEditor] = useState(false);
    const [codeEditor, setCodeEditor] = useState(false);
    const [loading, setLoading] = useState(false)
    const [videoLoading, setVideoLoading] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState()
    const [selectedTask, setSelectedTask] = useState()
    const [temp, setTemp] = useState(true)
    const [expandVideoComponent, setExpandVideoComponent] = useState(true)
    const [expandCodeEditorComponent, setExpandCodeEditorComponent] = useState(true)
    const [pointsRewarded, setPointsRewarded] = useState(0)
    const [taskPointsRewarded, setTaskPointsRewarded] = useState(0)
    const [code, setCode] = useState(``)
    const [videoSummary, setVideoSummary] = useState()
    const [outputDetails, setOutputDetails] = useState();
    const [collapsedVideoSize, setCollapsedVideoSize] = useState('350px');
    const [runCodeLoading, setRunCodeLoading] = useState(false);
    const [expantConsole, setExpantConsole] = useState(false)
    const [language, setLanguage] = useState()

    const { tenantDetails, courseDetails, setCourseDetails, selectedTopic, videoDetails, setVideoDetails, studentInfo, selectedCourse, setTaskDetails, setStudentInfo } = useContext(GeneralContext);
    const navigate = useNavigate()

    const handleShowVideoComponent = (t) => {
        setShowVideoComponent(t)
        setVideoComponent(t)
    }

    const handleShowCodeEditor = (t) => {
        if (t) {
            setExpandVideoComponent(false)
            setTimeout(() => {
                setShowCodeEditor(t)
                setCodeEditor(t)
            }, 200);
        }
        else {
            // setExpandCodeEditorComponent(true)
            setShowCodeEditor(t)
            setTimeout(() => {
                setCodeEditor(t)
            }, 1000);
            setTemp(true)
            setTimeout(() => {
                setExpandVideoComponent(true)
            }, 1000);
        }
    }

    const callZipy = () => {
        let student = localStorage.getItem("student_data")
        student = JSON.parse(student)

        zipy.identify(student?.email, {
            tenant_id: tenantDetails?.tenant_id,
            name: student?.name,
            email: student?.email,
            mobile_number: student?.mobile_number,
        });
    }

    useEffect(() => {
        // setTimeout(() => {
        //     handleShowVideoComponent(true)
        // }, 500);
        // setTimeout(() => {
        //     handleShowCodeEditor(true)
        // }, 1500);
    }, [])

    const startNextVideo = async () => {
        try {
            let changedVideoId = null
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            console.log(token)

            const { data } = await axios.get(`${API}/student-progress/videos?progress_id=${selectedTopic?.progress_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            for (let video of data?.data) {
                if (video?.status === "PENDING" || video?.status === "IN_PROGRESS") {
                    changedVideoId = video?.video_id
                    break;
                }
            }

            // selectedTopic?.videos?.some((video, i) => {
            //     if (video?.id === selectedVideo?.video_id) {
            //         flag = 1
            //     }
            //     if (flag === 1 && video?.id !== selectedVideo?.video_id) {
            //         changedVideoId = video?.id
            //         return true
            //     }
            // });

            for (let video of videoDetails) {
                if (video?.video_id === changedVideoId) {
                    handleVideoChange(video)
                    return video
                }
            }
            return null
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
        }
    }

    // const getVideos = async () => {
    //     try {
    //         let token = localStorage.getItem("token")
    //         let student = localStorage.getItem("student_data")

    //         if (!token || !student) {
    //             navigate('/login')
    //         }
    //         token = JSON.parse(token)
    //         student = JSON.parse(student)

    //         setVideoLoading(true)
    //         let pendingVideoId
    //         let firstVideoId
    //         let videosList = []
    //         if (selectedTopic?.videos?.length > 0) {
    //             firstVideoId = selectedTopic?.videos[0].id
    //         }
    //         selectedTopic?.videos?.forEach((video) => {
    //             videosList.push(video?.id)
    //         })
    //         selectedTopic?.videos?.some((video) => {
    //             if (video?.status === 'PENDING' || video?.status === 'IN_PROGRESS') {
    //                 pendingVideoId = video?.id
    //                 return true
    //             }
    //         })
    //         const { data } = await axios.post(`${API}/video/student-video-by-id`, {
    //             video_ids: videosList
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         setVideoDetails(data?.data)
    //         setVideoLoading(false)
    //         let firstVideo = null
    //         for (let v of data?.data) {
    //             if (v?.video_id === firstVideoId) {
    //                 firstVideo = v
    //             }
    //             if (v?.video_id === pendingVideoId) {
    //                 {
    //                     handleVideoChange(v)
    //                     return data?.data
    //                 }
    //             }
    //         }
    //         if (firstVideo) {
    //             // handleVideoChange(firstVideo)
    //         }
    //         else {
    //             setSelectedVideo(null)
    //         }

    //         return data?.data
    //     }
    //     catch (e) {
    //         if (e.response) {
    //             if (e.response?.data?.message === "Unauthorized") {
    //                 setStudentInfo(null)
    //                 localStorage.removeItem('student_data');
    //                 localStorage.removeItem('token');
    //                 navigate('/login')
    //             }
    //         }
    //         setVideoLoading(false)
    //         console.log(e.message)
    //     }
    // }

    const getTasks = async (taskComplete) => {
        try {
            // setTaskLoading(true)
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.get(`${API}/student-progress/tasks?progress_id=${selectedTopic?.progress_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setTaskDetails(data?.data)

            let videos = []
            for (let task of data?.data) {
                if (task.task_type === 'VID' && task.status === 'COMPLETED') {
                    videos?.push({
                        ...task?.video,
                        status: task?.status
                    })
                }
            }
            setVideoDetails(videos)
            if (taskComplete) {
                let flag = 0
                for (let task of data?.data) {
                    if (task.task_id === selectedTask.task_id) {
                        flag = 1
                    }
                    else if ((task?.status === "PENDING" || task?.status === "IN_PROGRESS") && flag === 1) {
                        handleTaskChange(task)
                        return {
                            tasks: data?.data,
                            task: task
                        }
                    }
                }
            }
            else {
                for (let task of data?.data) {
                    if (task?.status === "PENDING" || task?.status === "IN_PROGRESS") {
                        handleTaskChange(task)
                        return {
                            tasks: data?.data,
                            task: task
                        }
                    }
                }
            }
            setSelectedTask(null)
            return {
                tasks: data?.data,
                task: null
            }
        } catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            // setVideoLoading(false)
            console.log(e.message)
        }
    }

    const getCourseDetails = async () => {
        try {
            if (selectedCourse) {
                let token = localStorage.getItem("token")
                let student = localStorage.getItem("student_data")

                if (!token || !student) {
                    navigate('/login')
                }
                token = JSON.parse(token)
                student = JSON.parse(student)

                let url = `${API}/course/student-course-details?course_id=${selectedCourse?.course_id}&student_id=${student?.student_id}&batch_id=${selectedCourse?.batch_id}`
                // setLoading(true)
                const { data } = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setCourseDetails(data?.data)
                // setLoading(false)
                if (data?.data?.progress === 100) {
                    chatRef.current.handleCourseCompleteChat()
                }
                return data?.data
            }
            else {
                navigate('/')
            }
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            // setLoading(false)
            console.log(e.message)
        }
    }

    const handleVideoComplete = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            if (selectedTopic?.videos?.length < 1) {
                return
            }
            for (const video of selectedTopic?.videos || []) {
                if (selectedVideo?.video_id === video?.id && video?.status === "COMPLETED") {
                    return true
                }
            }
            const { data } = await axios.patch(`${API}/student-progress/video-complete`, {
                progress_id: selectedTopic?.progress_id,
                student_id: studentInfo.student_id,
                process_type_id: selectedVideo?.video_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (data?.data?.points_rewarded) {
                setPointsRewarded(data?.data?.points_rewarded)
            }
            getCourseDetails()
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const addHistory = async (task, option, uploadDetails, uploadLinkDetails, videoDetails) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            let history
            if (task?.task_type === 'CODE') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                constraints: selectedTask?.constraints,
                                language: selectedTask?.language,
                                task_type: selectedTask?.task_type,
                                explanation: selectedTask?.explanation,
                                task_title: selectedTask?.task_title
                            },
                            task_answer: {
                                code: code,
                                language: selectedTask?.language,
                            }
                        }
                    }
                ]
            }
            else if (task?.task_type === 'MCQ') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                mcq_options: selectedTask?.mcq_options,
                                task_type: selectedTask?.task_type,
                                image_url: selectedTask?.image_url,
                                code_snippet: selectedTask?.code_snippet,
                                explanation: selectedTask?.explanation,
                                task_title: selectedTask?.task_title,
                            },
                            task_answer: {
                                option: option,
                            }
                        }
                    }
                ]
            }
            else if (task?.task_type === 'DOC') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                task_type: selectedTask?.task_type,
                                doc_urls: selectedTask?.doc_urls ? [...selectedTask?.doc_urls] : [],
                                task_title: selectedTask?.task_title,
                            },
                        }
                    }
                ]
            }
            else if (task?.task_type === 'DOC_UPLOAD') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                task_type: selectedTask?.task_type,
                                doc_urls: selectedTask?.doc_urls ? [...selectedTask?.doc_urls] : [],
                                task_title: selectedTask?.task_title,
                                constraints: selectedTask?.constraints,
                            },
                            task_answer: {
                                submission_url: [uploadDetails?.url],
                                file_name: uploadDetails?.fileName,
                                file_size: uploadDetails?.fileSize,
                            }
                        }
                    }
                ]
            }
            else if (task?.task_type === 'DOC_LINK') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                task_type: selectedTask?.task_type,
                                doc_urls: selectedTask?.doc_urls ? [...selectedTask?.doc_urls] : [],
                                task_title: selectedTask?.task_title,
                                constraints: selectedTask?.constraints,
                            },
                            task_answer: {
                                submission_url: [uploadLinkDetails?.link],
                            }
                        }
                    }
                ]
            }
            else if (task?.task_type === 'VID') {
                history = [
                    {
                        type: 'task',
                        value: {
                            task_question: {
                                task_id: selectedTask?.task_id,
                                question: selectedTask?.question,
                                task_type: selectedTask?.task_type,
                                video: videoDetails
                            }
                        }
                    }
                ]
            }

            const result = await axios.post(`${API}/chat-history`, {
                history: history,
                progress_id: selectedTopic?.progress_id,
                student_id: student?.student_id,
                course_id: courseDetails?.course_id,
                batch_id: courseDetails?.batch_id,
                node_id: selectedTopic?.node_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log("Error", e.message)
        }
    }

    const addTaskSubmission = async (task, option, points_rewarded, uploadDetails, uploadLinkDetails) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            let taskBody
            if (task?.task_type === 'CODE') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {
                        code: code,
                    }
                }
            }
            else if (task?.task_type === 'MCQ') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {
                        option: option,
                    }
                }
            }
            else if (task?.task_type === 'DOC') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {}
                }
            }

            else if (task?.task_type === 'DOC_UPLOAD') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {
                        url: [uploadDetails?.url],
                        file_name: uploadDetails?.fileName,
                        file_size: uploadDetails?.fileSize,
                    }
                }
            }
            else if (task?.task_type === 'DOC_LINK') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {
                        url: [uploadLinkDetails?.link],
                    }
                }
            }
            else if (task?.task_type === 'VID') {
                taskBody = {
                    task_type: selectedTask?.task_type,
                    task_answer: {}
                }
            }

            const result = await axios.post(`${API}/task-submission`, {
                submission: {
                    task: taskBody
                },
                progress_id: selectedTopic?.progress_id,
                student_id: student?.student_id,
                batch_id: courseDetails?.batch_id,
                task_id: selectedTask?.task_id,
                points: points_rewarded
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log("Error", e.message)
        }
    }

    const handleTaskComplete = async (isCorrect, optionClicked, uploadDetails, uploadLinkDetails) => {
        try {
            let completedTask = selectedTask
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.patch(`${API}/student-progress/task-complete`, {
                progress_id: selectedTopic?.progress_id,
                student_id: student.student_id,
                process_type_id: selectedTask?.task_id,
                task_type: selectedTask?.task_type,
                is_correct: isCorrect
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (selectedTask?.task_type === 'MCQ') {
                addHistory(selectedTask, optionClicked, null)
                addTaskSubmission(selectedTask, optionClicked, data?.data?.points_rewarded, null)
            }
            else if (selectedTask?.task_type === 'DOC') {
                addHistory(selectedTask, null, null)
                addTaskSubmission(selectedTask, null, data?.data?.points_rewarded, null)
            }
            else if (selectedTask?.task_type === 'DOC_UPLOAD') {
                addHistory(selectedTask, null, uploadDetails)
                addTaskSubmission(selectedTask, null, data?.data?.points_rewarded, uploadDetails)
            }
            else if (selectedTask?.task_type === 'DOC_LINK') {
                addHistory(selectedTask, null, uploadDetails, uploadLinkDetails)
                addTaskSubmission(selectedTask, null, data?.data?.points_rewarded, uploadDetails, uploadLinkDetails)
            }
            else if (selectedTask?.task_type === 'VID') {
                addHistory(selectedTask, null, uploadDetails, uploadLinkDetails, selectedTask?.video)
                addTaskSubmission(selectedTask, null, data?.data?.points_rewarded, uploadDetails, uploadLinkDetails)
            }
            getCourseDetails()
            const result = await getTasks(true)
            setCode("")
            setOutputDetails(null)
            setTaskPointsRewarded(data?.data?.points_rewarded)
            chatRef.current.handleTaskCompleteChat(data?.data?.points_rewarded, result.tasks, result.task, completedTask)

            // let flag = 0
            // let nextTaskId

            // selectedTopic?.tasks?.some((task) => {
            //     if (task?.id === selectedTask?.task_id) {
            //         flag = 1
            //     }
            //     else if (flag === 1 && task?.status !== 'COMPLETED') {
            //         nextTaskId = task?.id
            //     }
            // })

            // taskDetails?.some((task) => {
            //     if (task?.task_id === nextTaskId) {
            //         handleTaskChange(task)
            //         return true
            //     }
            // })
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const handleVideoChange = async (video) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            let status
            selectedTopic?.videos?.forEach((v) => {
                if (v.id === video.video_id) {
                    status = v.status
                }
            })
            setSelectedVideo(video)
            if (status !== 'IN_PROGRESS' && status !== 'COMPLETED') {
                const result = await axios.patch(`${API}/student-progress/started-process`, {
                    progress_id: selectedTopic?.progress_id,
                    student_id: student?.student_id,
                    process_type: "videos",
                    process_type_id: video?.video_id
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const handleTaskChange = async (task) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            let status
            selectedTopic?.tasks?.forEach((t) => {
                if (t.id === task.task_id) {
                    status = t.status
                }
            })
            setSelectedTask(task)
            setLanguage(task?.language)
            if (status !== 'IN_PROGRESS' && status !== 'COMPLETED') {
                const result = await axios.patch(`${API}/student-progress/started-process`, {
                    progress_id: selectedTopic?.progress_id,
                    student_id: student?.student_id,
                    process_type: "tasks",
                    process_type_id: task?.task_id
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const skipAndStartNextTask = async () => {
        try {
            setCode("")
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            // setTaskLoading(true)
            const { data } = await axios.get(`${API}/student-progress/tasks?progress_id=${selectedTopic?.progress_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setTaskDetails(data?.data)
            let videos = []
            for (let task of data?.data) {
                if (task.task_type === 'VID' && task.status === 'COMPLETED') {
                    videos?.push({
                        ...task?.video,
                        status: task?.status
                    })
                }
            }
            setVideoDetails(videos)
            let flag = 0
            for (let task of data?.data) {
                if (task.task_id === selectedTask.task_id) {
                    flag = 1
                }
                else if ((task?.status === "PENDING" || task?.status === "IN_PROGRESS") && flag === 1) {
                    handleTaskChange(task)
                    return task
                }
            }
            setSelectedTask(null)
            return null
        } catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            // setVideoLoading(false)
            console.log(e.message)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!courseDetails || !selectedTopic) {
                navigate(`/`);
                return;
            }
            setLoading(true)
            // await getVideos();
            await getTasks();
            setLoading(false)
        };
        fetchData();
    }, [selectedTopic])

    useEffect(() => {
        if (expandCodeEditorComponent) {
            setCollapsedVideoSize('350px')
        }
        else {
            setCollapsedVideoSize('50px')
        }
    }, [expandCodeEditorComponent])

    useEffect(() => {
        callZipy()
    }, [])

    return (
        <div className='h-full w-full flex items-center justify-center'>
            {/* <button onClick={() => {
                setExpandCodeEditorComponent(!expandCodeEditorComponent)
            }}>
                OP
            </button> */}
            {
                loading ?
                    <CircularProgress />
                    :
                    <div className='learn-container'>
                        <div className={`chats-wrapper ${showVideoComponent ? 'left' : ''}`}>
                            <Chat ref={chatRef} language={language} setExpandCodeEditorComponent={setExpandCodeEditorComponent} setExpantConsole={setExpantConsole} setRunCodeLoading={setRunCodeLoading} outputDetails={outputDetails} setOutputDetails={setOutputDetails} startNextVideo={startNextVideo} handleVideoComplete={handleVideoComplete} skipAndStartNextTask={skipAndStartNextTask} selectedVideo={selectedVideo} handleTaskComplete={handleTaskComplete} videoComponent={videoComponent} videoSummary={videoSummary} setVideoSummary={setVideoSummary} code={code} selectedTask={selectedTask} pointsRewarded={pointsRewarded} setPointsRewarded={setPointsRewarded} handleShowVideoComponent={handleShowVideoComponent} handleShowCodeEditor={handleShowCodeEditor} codeEditor={codeEditor} setSelectedVideo={setSelectedVideo} />
                        </div>
                        {
                            videoComponent &&
                            <Slide direction="left" in={showVideoComponent} timeout={900}>
                                <div style={{ position: 'absolute', width: '48%', height: `fit-content`, right: '2.2%' }}>
                                    <VideoComponent setExpandCodeEditorComponent={setExpandCodeEditorComponent} collapsedVideoSize={collapsedVideoSize} startNextVideo={startNextVideo} setVideoSummary={setVideoSummary} handleVideoComplete={handleVideoComplete} handleVideoChange={handleVideoChange} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} videoLoading={videoLoading} expandVideoComponent={expandVideoComponent} setExpandVideoComponent={setExpandVideoComponent} />
                                </div>
                            </Slide>
                        }
                        {
                            codeEditor &&
                            <Slide direction="up" in={showCodeEditor} timeout={900}>
                                <div
                                    className={`${expandCodeEditorComponent ? 'code-editor-expanded' : 'code-editor-collapsed'} ${showCodeEditor ? 'code-editor-up' : 'code-editor-down'} code-editor-wrapper`}
                                    style={{ position: 'absolute', width: '48%', right: '2.2%', flex: 1, bottom: '10px' }}>
                                    <div style={{ height: '100%' }}>
                                        <CodeEditor language={language} setLanguage={setLanguage} expantConsole={expantConsole} setExpantConsole={setExpantConsole} runCodeLoading={runCodeLoading} setRunCodeLoading={setRunCodeLoading} selectedTask={selectedTask} setExpandCodeEditorComponent={setExpandCodeEditorComponent} expandCodeEditorComponent={expandCodeEditorComponent} outputDetails={outputDetails} setOutputDetails={setOutputDetails} code={code} setCode={setCode} expandVideoComponent={expandVideoComponent} setExpandVideoComponent={setExpandVideoComponent} />
                                    </div>
                                </div>
                            </Slide>
                        }
                    </div>
            }

        </div>
    )
}

export default Learn